import logo from './logo.svg';
import Header from './components/Header/Header'
import Home from './pages/Home/Home';


import './App.css';

function App() {
  return (
    <div className=" ">
     

        
        <Header />
        <Home />
        
        
                
    
    </div>
  );
}

export default App;
