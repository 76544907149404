import React from 'react'
import LogoGB from "./GoodBooksPro.png"
import './Styles.css'


function Logo() {
  return (
    <div className='logoGB'> <img className='logoGBimg' src={LogoGB} /></div>
  )
}

export default Logo