import React from 'react'
import Logo from '../Logo/Logo'
import './Styles.css'

function Header() {
  return (
    <div className='header'>

        <Logo />





    </div>
  )
}

export default Header